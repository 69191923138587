.nes-portlet-filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 20px 0;
  position: relative;

  &::after {
    content: ' ';
    width: 100%;
    height: 5px;
    background-color: $mda-gray-100;
    position: absolute;
    bottom: 15px;
    z-index: 5;
  }

  mat-form-field {
    z-index: 10;

    .mat-form-field-infix {
      min-width: 300px;
      padding: 5px 0 10px 0;
    }

    .mat-form-field-underline {
      height: 5px !important;
      background-color: $mda-gray-500;
    }
  }
}
