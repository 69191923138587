.nes-input-address-secondary {
  display: flex;
  align-items: center;
  justify-content: space-between;

  mat-form-field {
    flex: 1 1 14%;
    max-width: 14%;

    &:first-of-type {
      flex: 1 1 auto;
      max-width: 100%;
    }

    &:last-of-type {
      flex: 1 1 120px;
      max-width: 120px;
    }
  }
}
