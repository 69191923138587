.nes-header-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-bottom: 10px;

  &-badge {
    border-radius: 15px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    height: 28px;
    margin: 0 6px;
    padding: 0 14px 0 30px;
    position: relative;

    &:focus {
      outline: none;
    }

    .mat-icon {
      @include size(15px);
      position: absolute;
      top: 8px;
      left: 8px;
      color: #fff;
      fill: #fff;
    }

    strong {
      font-weight: 600;
    }

    &._warning {
      color: #fff;
      background: $mda-color-200;
      box-shadow: 0 4px 7px 0 $mda-color-200, 0 1px 1px 0 $mda-color-100;
    }

    &._info {
      color: #fff;
      background: $mda-gray-700;
      box-shadow: none;
    }
  }
}
