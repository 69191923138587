.nes-input-email-group {
  .mat-chip{
    background-color: #5D768C !important;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    box-shadow: 0 1px 4px 0 rgba(93,118,140,0.69);
    .mat-chip-remove{
      border-radius: 50%;
      background-color: #819CB3 !important;
      font-size: 12px !important;
      text-align: center !important;
      line-height: 19px !important;
      font-weight: 900 !important;
      color: #fff !important;
      opacity: 1 !important;
    }
  }
}