


@mixin dossiers-details-billing-theme($theme){
    $primary: map-get($theme, primary);

    $nes-primary-900:  mat-color($primary, 900);

   
    dossier-details-billing {
        .nes-portlet-table{
            mat-header-row{
                mat-header-cell{
                    color: $nes-primary-900 !important;
                }
            }
        }
        
        .nes-table-action-box{
            &._full_width{
                button{
                    &:disabled,
                    &.disabled{
                        color: #fff !important;
                    }
                }
            }
        }
    }
}