

@mixin dossier-movement-source-and-destination-controls-theme($theme) {
    $primary: map-get($theme, primary);
    $nes-primary-900:  mat-color($primary, 900);
    
    .dossier-movement-source-and-destination-container{
        .mat-chip{

            background-color: $nes-primary-900 !important;
            color: #fff !important;
            box-shadow: 0 1px 4px 0 rgba(93,118,140,0.69);
            .mat-chip-remove{

                background-color: #819CB3 !important;
                color: #fff !important;
            }
        }
    }
}