@import 'assets/styles/theme';
@import '@nes/nes-common-components/nes-common-components.theme.scss';
@import '@nes/price-list-ui-components/price-list-ui-components.theme.scss';
// TODO: uncomment after @nes/file-manager source code has been retrieved and updated to Angular 11
//@import '@nes/file-manager/file-manager.theme.scss';
@import './app/modules/vehicles/vehicles.module.theme.scss';
@import './app/modules/dossiers/dossiers.module.theme.scss';
@import './app/modules/billing/billing.module.theme.scss';
@import './app/shared/layout/global-styles/global-styles.theme.scss';

@mixin size($width, $height: $width) {
  width: $width !important;
  height: $height !important;
}

@mixin max-size($max-width, $max-height: $max-width) {
  max-width: $max-width;
  max-height: $max-height;
}

@mixin min-size($min-width, $min-height: $min-width) {
  min-width: $min-width;
  min-height: $min-height;
}

@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }

  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
      font-family: $font-family;
      src: url('#{$file-path}.svg##{$font-family}') format('svg');
    }
  }

  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-Thin', 100);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-ExtraLight', 200);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-Light', 300);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-Regular', 400);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-Medium', 500);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-SemiBold', 600);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-Bold', 700);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-ExtraBold', 800);
  @include font-face('Montserrat', '~src/lib/nes-fonts/Montserrat-Black', 900);
}

$mq-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1440px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1441px
) !default;

$mq-breakpoints: map-merge($grid-breakpoints,
  (
    xs-max: map-get($grid-breakpoints, sm) - 1,
    sm-max: map-get($grid-breakpoints, md) - 1,
    md-max: map-get($grid-breakpoints, lg) - 1,
    lg-max: map-get($grid-breakpoints, xl) - 1
  )
);

$nes-typography: mat-typography-config($font-family: 'Montserrat');

$nes-default-gray: (
  50: #f5f7f9,
  100: #f4f4f4,
  200: #eef0f2,
  300: #b3b6b8,
  400: #d4dadf,
  500: #aabccb,
  600: #6e96b9,
  700: #7d8286,
  800: #333537,
  900: #5d768c,
  A100: #ffffff,
  A200: #ffffff,
  A400: #cfe8ff,
  A700: #b6dcff,
  contrastDefaultColor: 'dark'
);

$nes-contrasts: (
  50: #fff4e0,
  100: #ffe5b3,
  200: #d62d20,
  300: #0057e7,
  400: #008744,
  500: #ffa700,
  600: #DB634D,
  700: #ed9332,
  800: #ffd3a4,
  900: #b96e6e,
  A100: #ffffff,
  A200: #fff8f2,
  A400: #ffdbbf,
  A700: #ffcca6,
  darkText: 'dark'
);

$theme: mat-light-theme($nes-default-gray, $nes-contrasts);


.nes-custom-modalbox{
  mat-dialog-container{
    overflow: hidden !important;
    .nes-dialog{
      overflow: auto !important;
    }
  }
}


@include mat-core();
@include angular-material-theme($theme);
@include angular-material-typography($nes-typography);


@include nes-common-components-theme($theme);
@include vehicles-module-theme($theme);
@include dossiers-module-theme($theme);
@include price-list-ui-components-theme($theme);
@include billing-module-theme($theme);
// TODO: uncomment after @nes/file-manager source code has been retrieved and updated to Angular 11
//@include file-manager-theme($theme);
@include nes-global-styles-theme($theme);


.table-container {
  position: relative;
  .table-loading-shade {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    .mat-spinner circle {
      stroke: #aabccb !important;
    }
  }
}

.nes-sidenav-brand {
  height: 120px !important;
}

@media print {
  html {
    height: auto !important;
  }
  
  .mat-sidenav-content {
    overflow: hidden !important;
  }
}
