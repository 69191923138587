

@mixin dossier-list-theme($theme) {
    $primary: map-get($theme, primary);
    $nes-primary-800:  mat-color($primary, 800);

    app-dossier-list{

        mat-table{
            mat-header-cell, 
            mat-cell{

                .placeholder{
    
                    border-bottom: 1px solid $nes-primary-800;
                }
            }
        }
    
        .nes-portlet-filter-bar-col{
            
            button{
                &:hover{
                    
                    color: #fff !important;
                }
            }
        }
    }
}