@import '~@angular/material/theming';

// typography
$mda-typography: mat-typography-config($font-family: 'Montserrat');

// colors
$mda-primary: mat-palette($mat-teal);
$mda-accent: mat-palette($mat-gray);
$mda-warn: mat-palette($mat-red);
$mda-theme: mat-light-theme($mda-primary, $mda-accent, $mda-warn);

@include mat-core();
@include angular-material-theme($mda-theme);
@include angular-material-typography($mda-typography);

// --

mat-checkbox {

  &.mat-checkbox-indeterminate,
  &.mat-checkbox-checked {

    .mat-checkbox-background {
      background: $mda-color-200 !important;
    }
  }

  .mat-checkbox-inner-container {
    @include size(14px);

    &:hover {

      .mat-checkbox-persistent-ripple {
        opacity: 0;
      }
    }

    .mat-checkbox-frame {
      border-color: $mda-gray-900;
    }
  }

  &.selector {
    font-size: 12px;
    color: $mda-gray-800;
    margin-left: 10px;

    .mat-checkbox-background {
      transition: none !important;

      svg {
        display: none !important;
        opacity: 0 !important;
      }
    }

    &.mat-checkbox-checked {

      .mat-checkbox-background {
        @include size(13px);
        background-color: $mda-gray-100 !important;
        border-radius: 50%;
        box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, 0.31), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        left: 4px;
        top: 4px;
      }
    }

    .mat-checkbox-label {
      padding: 7px 0 0 8px;
    }

    .mat-checkbox-inner-container {

      .mat-checkbox-frame {
        @include size(21px);
        background-color: $mda-gray-500;
        border-color: $mda-gray-500;
        border-radius: 50%;
      }

      .mat-checkbox-persistent-ripple {
        opacity: 0 !important;
      }
    }
  }
}

mat-datepicker {

  &-toggle {

    .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }
}

mat-dialog-container {
  padding: 40px 50px !important;

  .mat-dialog-title {
    font-size: 14px;
    font-weight: 400;
  }

  .mat-dialog-content {

    mat-icon {
      @include size(74px);
      color: $mda-color-200;
    }

    h2 {
      font-size: 22px;
      font-weight: 400;
    }

    p {
      font-size: 12px;
      font-weight: 400;

      strong {
        font-weight: 500;
      }
    }
  }

}

mat-form-field {
  font-size: 12px !important;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  &.mat-focused {

    .mat-form-field {

      &-ripple {
        display: none;
      }
    }
  }

  &.mat-form-field-invalid {

    .mat-form-field-underline {
      background: $mda-color-400 !important;
    }
  }

  &.mat-form-field-disabled {

    .mat-form-field-label {
      color: rgba(0, 0, 0, .38) !important;
    }
  }

  .mat-form-field {

    &-label {
      color: $mda-gray-800 !important;
    }

    &-underline {
      height: 2px !important;
      background: $mda-gray-500 !important;
    }

    &-suffix {
      padding: 4px;

      mat-icon {
        @include size(14px);
        color: $mda-gray-900 !important;
      }
    }

    &-ripple {
      height: 0 !important;
    }
  }
}

mat-option {

  .mat-option-text {
    font-weight: 400;
    color: $mda-gray-800;
  }
}

mat-paginator {
  font-weight: 500;
  color: $mda-gray-700;

  .mat-icon-button[disabled][disabled] {
    .mat-paginator-icon {
      color: $mda-gray-500 !important;
    }
  }

  .mat-paginator-icon {
    @include size(22px);
    color: $mda-gray-700;
  }
}

mat-radio-group {
  font-size: 12px;
  color: $mda-gray-800;

  mat-radio-button {
    margin-right: 37px;

    &:last-child {
      margin-right: 0;
    }

    &.mat-radio-checked {

      .mat-radio-container {

        .mat-radio-outer-circle {
          border-color: $mda-gray-500 !important;
          box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, .1);
        }

        .mat-radio-inner-circle {
          background-color: $mda-gray-100 !important;
          box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, .31), 0 1px 2px 0 rgba(0, 0, 0, .1);
        }
      }
    }

    &.mat-radio-disabled {

      .mat-radio-outer-circle {
        border-color: $mda-gray-500 !important;
      }
    }

    .mat-radio-container {

      .mat-radio-outer-circle {
        @include size(21px);
        background-color: $mda-gray-500;
        border-color: $mda-gray-500;
      }

      .mat-radio-inner-circle {
        @include size(13px);
        transform: none;
        top: 4px;
        left: 4px;
        background-color: $mda-gray-500 !important;
      }

      .mat-radio-ripple {
        opacity: 0 !important;
      }
    }
  }
}

mat-select {

  .mat-select-value-text {
    font-weight: 400;
    color: $mda-gray-800;
  }
}

mat-sidenav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  background: #fff;
  border-right: none;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.10);
  overflow-x: hidden;

  &-container {
    height: 100%;
  }

  &-content {
    height: 100%;
    padding: 20px;
    margin-left: 80px !important;

    @include mq(xl) {

      &.toggled {
        margin-left: 260px !important;
      }
    }
  }
}

mat-slide-toggle {

  &.mat-checked {

    .mat-slide-toggle {

      &-bar {
        background-color: $mda-color-300 !important;
      }

      &-thumb {
        background-color: $mda-color-200 !important;

        &-container {
          transform: translate3d(18px, 0, 0) !important;
        }
      }
    }
  }

  .mat-slide-toggle {

    &-content {
      font-size: 12px !important;
    }

    &-ripple {
      display: none;
    }
  }

  &.selector {

    &.mat-checked {

      .mat-slide-toggle {

        &-bar {
          background-color: $mda-gray-700 !important;
        }

        &-thumb {
          background-color: $mda-gray-100 !important;

          &-container {
            transform: translate3d(39px, 0, 0) !important;
          }
        }
      }
    }

    .mat-slide-toggle-bar {
      @include size(64px, 24px);
      border-radius: 12px;
      background-color: $mda-gray-500;
    }

    .mat-slide-toggle-thumb {
      @include size(18px);
      box-shadow: 1px 4px 6px 0 rgba(0, 0, 0, .31), 0 1px 2px 0 rgba(0, 0, 0, .1);
      background-color: $mda-gray-100;

      &-container {
        top: 3px;
        left: 3px;
      }
    }
  }
}

mat-table {

  mat-header {

    &-row {
      border-bottom: none;
    }

    &-cell {
      color: $mda-gray-700 !important;
      font-weight: 400 !important;

      &:first-of-type {
        padding-left: 4px;
      }

      &:last-of-type {
        padding-right: 4px;
      }
    }
  }

  mat-row {
    min-height: 40px;
    border-bottom: none;

    &.highlight {
      background: rgba(148, 173, 198, .31);

      mat-cell {
        border-bottom: 1px solid rgba(148, 173, 198, .31);
        font-weight: 600;
      }
    }

    &:hover {
      background: rgba(220, 220, 220, .38);
    }

    mat-cell {
      font-size: 12px !important;
      color: $mda-gray-800 !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      &.no-border {
        border-bottom: 1px solid rgba(0, 0, 0, 0);
      }

      &:first-of-type {
        padding-left: 4px;
      }

      &:last-of-type {
        padding-right: 4px;
      }

      &.mat-column-actions {

        .mat-icon-button {
          @include size(30px);
          line-height: 30px;
        }

        mat-icon {
          @include size(18px);
        }
      }
    }
  }
}

mat-tab-group {

  .mat-tab-header {
    border: none;
    background: $mda-gray-100;

    .mat-tab-label {
      height: 73px;
      opacity: 1 !important;

      &-content {
        padding-top: 18px;
        font-size: 12px;
        font-weight: 500;
        color: $mda-gray-800;
        text-transform: uppercase;
      }
    }

    .mat-ink-bar {
      height: 3px;
      background-color: $mda-color-200 !important;
    }
  }

  .mat-tab-body-wrapper {
    padding: 35px 0;
  }
}

// --

.mat-autocomplete-panel {

  mat-option {
    height: 36px;
    line-height: 36px;

    .mat-option-text {
      font-size: 12px;
    }
  }
}

.mat-button {

  &.form-icon-button {
    height: 30px;
    min-width: 100px;
    background-color: $mda-gray-100;
    font-size: 12px;
    line-height: 30px;
    color: $mda-gray-900;

    span.label {
      padding: 0 8px 0 24px;
    }

    mat-icon {
      @include size(16px);
      color: $mda-gray-600;
      position: absolute;
      top: 4px;
      left: 16px;
    }
  }

  &.form-alert-button {
    height: 30px;
    min-width: 100px;
    background-color: $mda-color-400;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2), 0 0 1px 0 rgba(128, 61, 61, 1);
  }

  &.form-action-button {
    height: 30px;
    min-width: 100px;
    background-color: $mda-gray-600;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2), 0 0 1px 0 rgba(84, 112, 156, 1);
  }

  &.form-submit-button {
    height: 30px;
    min-width: 100px;
    background-color: $mda-color-200;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2), 0 0 1px 0 rgba(186, 109, 28, 1);
  }

  &.form-undo-button {
    height: 30px;
    min-width: 100px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    color: $mda-gray-700;
    box-shadow: none;
  }
}

// --

.mat-input-element:disabled {
  color: rgba(0, 0, 0, .87);
}

.mat-input-element:not([disabled]) {

  &.nes-action-input {
    cursor: pointer;
  }
}

.mat-form-field-suffix {

  .nes-action-input {
    cursor: pointer;
  }
}

// --

.nes-snackbar {

  simple-snack-bar {
    justify-content: center;

    span {
      font-size: 12px;
    }
  }
}
