body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  background: $mda-gray-200;
  overflow: hidden;
}

body {
  overflow: auto;
}
