.nes-navigation {
  margin-top: 40px;

  mat-expansion-panel {
    border-radius: 0;
    box-shadow: none !important;

    mat-nav-list {
      padding: 0;

      .mat-list-item {
        height: 38px !important;
        font-size: 13px !important;
        font-weight: 500 !important;
        color: $mda-gray-900 !important;

        &.active {
          color: $mda-color-200 !important;
        }

        &-content {
          padding: 0 0 0 10px !important;
        }

        &:hover,
        &:focus {
          background: #fff;
        }
      }
    }

    &-header {
      padding: 0 16px 0 20px;
      font-size: 13px !important;
      font-weight: 500 !important;

      mat-panel-title {
        color: $mda-gray-900 !important;
      }
    }

    &.mat-expanded {
      border-bottom: 1px solid $mda-gray-400;
    }

    .mat-expansion-panel-body {
      padding: 0 24px 10px !important;
    }
  }

  mat-nav-list {
    padding: 0;

    .mat-list-item {
      height: 38px !important;
      font-size: 13px !important;
      font-weight: 500 !important;
      color: $mda-gray-900 !important;

      &.active {
        color: $mda-color-200 !important;
      }

      &-content {
        padding: 0 0 0 20px !important;
      }
    }
  }
}
