.nes-sheet-action {

  mat-list-item {

    &._disabled {
      pointer-events: none;

      .mat-list-item-content {
        opacity: 0.4;
      }
    }

    .mat-list-item-content {
      justify-content: space-between;

      .text-content span {
        font-size: 13px !important;
        color: $mda-gray-800;
      }
    }
  }
}
