.nes-sidenav {

  &-toggle {
    @include size(80px, 100%);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 100;
    background: #fff;
    border-right: none;
    box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.10);
    padding-top: 18px;
    position: absolute;
    top: 0;
    left: 0;

    &.opened {
      display: none !important;
    }

    button {
      @include size(38px);
      background: #fff;
      color: $mda-gray-900;
    }
  }

  &-brand {
    @include size(175px, 39px);
    margin: 45px auto 88px;
    fill: $mda-gray-900;
    .sprite{
      img{
        max-width: 100%;
      }
    }
  }

  &-link {
    text-align: center;

    a {
      @include size(190px, 46px);
      border-radius: 3px;
      background: #eeeeee;

      .mat-button-focus-overlay {
        opacity: 0 !important;
      }

      img {
        @include size(95px, 26px);
        margin-top: 10px;
      }
    }
  }

  &-menu {
    margin-top: 40px;
    padding-top: 0 !important;

    &-item {

      &:hover {
        background: $mda-gray-100 !important;
      }

      span {
        padding-left: 4px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $mda-gray-900;
      }
    }
  }

  &-version {
    position: absolute;
    bottom: 20px;
    width: 220px;
    padding: 0 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: $mda-gray-700;
    text-align: center;

    @include mq(xl) {
      text-align: left;
    }

    span {
      color: $mda-gray-800;
    }

    mat-slide-toggle {
      position: absolute;
      top: -4px;
      right: 20px;
    }
  }
}

@media print {
  .nes-sidenav-toggle {
    display: none !important;

    + mat-sidenav-container mat-sidenav-content {
      margin-left: 0 !important;
      padding: 0 !important;
    }
  }

  .mat-drawer-content {
    background: none !important;
  }
}


@media print {
  html,
  body {
    background: none !important;
  }
}