.nes-input-phone-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  nes-input-phone {
    flex: 1 1 100%;
  }

  &-button {
    margin-left: 10px;

    .mat-icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $mda-gray-600;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(84, 112, 156, 1);

      span {
        position: relative;
        top: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    mat-icon {
      width: 10px;
      height: 10px;
      line-height: 10px !important;
      color: #fff;
    }
  }
}
