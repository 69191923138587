.sprite {
  @include size(100%);
  display: inline-block;
  vertical-align: top;

  svg {
    @include size(100%);
    display: inline-block;
    vertical-align: top;
  }
}

// --

.circular {
  animation: rotate 2s linear infinite;
  height: 100px;
  position: relative;
  width: 100px;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: $alfresco-theme-red-100;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
