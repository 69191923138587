
@mixin vehicle-vin-taken-dialog-theme($theme){
    $accent: map-get($theme, accent);
    $nes-accent-700:  mat-color($accent, 700);
    .cdk-overlay-container{
        .vehicle-vin-taken-dialog{
            p{
                b{
                    color: $nes-accent-700;  
                }
            }
        }
    }
}