$alfresco-theme-red-100: #d62d20;
$alfresco-theme-blue-100: #0057e7;
$alfresco-theme-green-100: #008744;
$alfresco-theme-yellow-100: #ffa700;

$mda-gray-100: #f4f4f4;
$mda-gray-200: #eef0f2;
$mda-gray-300: #b3b6b8;
$mda-gray-400: #d4dadf;
$mda-gray-450: #d7d8d8;

$mda-gray-500: #aabccb;
$mda-gray-600: #6e96b9;
$mda-gray-700: #7d8286;
$mda-gray-800: #333537;
$mda-gray-900: #5d768c;

$mda-color-100: #6f3e0a;
$mda-color-200: #ed9332;
$mda-color-300: #ffd3a4;
$mda-color-400: #b96e6e;


