@import 'billing/components/nes-input-address-primary/nes-input-address-primary.component';
@import 'billing/components/nes-input-address-secondary/nes-input-address-secondary.component';
@import 'billing/components/nes-input-email-group/nes-input-email-group.component';
@import 'billing/components/nes-input-fiscal-code/nes-input-fiscal-code.component';
@import 'billing/components/nes-input-legal-representative/nes-input-legal-representative.component';
@import 'billing/components/nes-input-office-selection/nes-input-office-selection.component';
@import 'billing/components/nes-input-payment-expire/nes-input-payment-expire.component';
@import 'billing/components/nes-input-payment-type/nes-input-payment-type.component';
@import 'billing/components/nes-input-phone-group/nes-input-phone-group.component';
@import 'billing/components/nes-input-phone/nes-input-phone.component';
@import 'billing/components/nes-input-vat-exemption/nes-input-vat-exemption.component';
@import 'billing/components/nes-input-vat-group/nes-input-vat-group.component';
@import 'billing/components/nes-list-contact/nes-list-contact.component';

@import 'layout/components/nes-counter-badge/nes-counter-badge.component';
@import 'layout/components/nes-dialog-delete/nes-dialog-delete.component';
@import 'layout/components/nes-footer/nes-footer.component';
@import 'layout/components/nes-header-alert/nes-header-alert.component';
@import 'layout/components/nes-header/nes-header.component';
@import 'layout/components/nes-navigation/nes-navigation.component';
@import 'layout/components/nes-portlet-actions/nes-portlet-actions.component';
@import 'layout/components/nes-portlet-filters/nes-portlet-filters.component';
@import 'layout/components/nes-portlet-form/nes-portlet-form.component';
@import 'layout/components/nes-sidenav-layout/nes-sidenav-layout.component';


.print-header {
  text-align: center;
  display: none;
  margin-top: -10px;

  h2 {
    position: relative;
    margin-top: 0px;
    z-index: 3;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.nes-snackbar-error {
  background: #e74e4e !important;

  simple-snack-bar {
    justify-content: center;

    span {
      font-size: 12px;
    }
  }
}
