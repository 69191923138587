.nes-footer {
  display: block;
  text-align: center;
  margin-top: 10px;
  height: 47px;

  &-copyright {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: $mda-gray-700;
    padding: 4px 90px;
    border-bottom: 5px solid $mda-gray-500;

    span {
      font-weight: 500;
    }
  }
}

@media print {
  .nes-footer {
    display: none !important;
  }
}
