
@mixin dossier-details-vehicles-theme($theme) {
    $primary: map-get($theme, primary);
    $nes-primary-900:  mat-color($primary, 900);

    dossier-details-vehicles{
        
        .nes-table-action-box{
            &._full_width{
                button{
                    &:disabled,
                    &.disabled{

                        opacity: 0.3;
                        color: #fff !important;
                    }
                }
            }
        }
        
        mat-table{
            mat-header-cell{
                color: $nes-primary-900 !important;
            }
        }
    }
}