.nes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-bottom: 10px;

  &-box {

    mat-icon {
      @include size(22px);
      color: $mda-gray-900;

      &._light {
        @include size(16px);
        color: $mda-gray-600;

        &:hover {
          cursor: pointer;
        }
      }

      &._left {
        margin-right: 16px;
      }

      &._right {
        margin-left: 16px;
      }
    }
  }

  &-time {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    text-transform: capitalize;
    color: $mda-gray-700;

    span {
      display: block;
      color: $mda-gray-800;
    }
  }

  &-user {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    color: $mda-gray-900;
    text-align: right;

    span {
      display: block;
      color: $mda-gray-800;
    }
  }

  &-badge {
    height: 30px;
    border-radius: 15px;
    border: none;
    padding: 0 14px 0 30px;
    margin: 0 6px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: relative;

    mat-icon {
      @include size(15px);
      position: absolute;
      top: 8px;
      left: 8px;
      color: #fff;
      fill: #fff;
    }

    strong {
      font-weight: 600;
    }

    &._warning {
      color: #fff;
      background: $mda-color-200;
      box-shadow: 0 4px 7px 0 $mda-color-200, 0 1px 1px 0 $mda-color-100;
    }

    &._info {
      color: #fff;
      background: $mda-gray-700;
      box-shadow: none;
    }
  }
}