
@mixin ngx-material-timepicker-theme($theme) {
    $primary: map-get($theme, primary);
    
    $nes-primary-100: mat-color($primary, 100);
    $nes-primary-600: mat-color($primary, 600);
    

    ngx-material-timepicker-content{
        width: 360px;
        .timepicker{
        width: 100% !important;
        .timepicker__body{
            padding: 50px;
            .clock-face{
                background-color: $nes-primary-100;
            }
            .clock-face__number > span{
                font-family: Montserrat !important;
                &.active{
                    background-color: $nes-primary-600;
                }
            }
            .clock-face__clock-hand{
                background-color: $nes-primary-600;
                &:before{
                border: 4px solid $nes-primary-600;
                }
            }
        }
        .timepicker__header{
            background-color: $nes-primary-600;
            ngx-material-timepicker-dial-control input{
                width: 70px;
                font-family: Montserrat !important;
                &:focus{
                outline: none;
                }
            }
            ngx-material-timepicker-period{
                display: none !important;
            }
        }
        .timepicker__actions{
            .cancel_button{
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
            .mat-button-wrapper{
                font-size: 12px;
                font-weight: 400;
                line-height: 30px;
                color: $nes-primary-600;
            }
            }
        }
        }
    }
}