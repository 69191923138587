@include font-face('Montserrat', '/assets/fonts/Montserrat-Thin', 100);
@include font-face('Montserrat', '/assets/fonts/Montserrat-ExtraLight', 200);
@include font-face('Montserrat', '/assets/fonts/Montserrat-Light', 300);
@include font-face('Montserrat', '/assets/fonts/Montserrat-Regular', 400);
@include font-face('Montserrat', '/assets/fonts/Montserrat-Medium', 500);
@include font-face('Montserrat', '/assets/fonts/Montserrat-SemiBold', 600);
@include font-face('Montserrat', '/assets/fonts/Montserrat-Bold', 700);
@include font-face('Montserrat', '/assets/fonts/Montserrat-ExtraBold', 800);
@include font-face('Montserrat', '/assets/fonts/Montserrat-Black', 900);

// --

strong {
  font-weight: 700;
}
