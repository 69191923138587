

@mixin dossier-details-general-theme($theme) {
    $primary: map-get($theme, primary);
    
    $nes-primary-100: mat-color($primary, 100);
    $nes-primary-500: mat-color($primary, 500);
    $nes-primary-700:  mat-color($primary, 700);


    nes-dossier-details-general {

        .nes-portlet-form-wr{
            .nes-portlet-form-col{
                &._three{
    
                    mat-slide-toggle{
                        .mat-slide-toggle-label{
                            .mat-slide-toggle-bar{
                                background-color: #EAEAEA !important;
                                box-shadow: inset 0  1px 1px 0 rgba(0, 0, 0, 0.04);
                                
                                .mat-slide-toggle-thumb-container{
    
                                    .mat-slide-toggle-thumb{
                                        background-color: $nes-primary-700;
                                        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.11), 0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
                                    }
                                }
                            }
                        }
                    }
                }
                &.hour_field{
                    &.disabled{
                        color: rgba(0, 0, 0, 0.38) !important;
                    }
                }
            } 
            ngx-timepicker-field{
                .ngx-timepicker{
    
                    border-bottom: 2px solid $nes-primary-500;
                    &.ngx-timepicker--disabled{
    
                        background-color: transparent !important;
                    }
                }
            }
            dossier-movement-source-and-destination-controls{
                background-color: $nes-primary-100;
            }
        }
    }
    
}