.nes-portlet-actions {
  padding: 37px 40px 20px;
  background: $mda-gray-200;

  &-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mat-form-field {
    display: inline;

    &-infix {
      width: 100% !important;
    }
  }

  &-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 80%;

    ._action-item {
      flex-grow: 1;
      flex-shrink: 1;

      &._third {
        margin-right: 16px;
        max-width: calc(33% - 16px);

        @include mq(lg) {
          margin-right: 46px;
          max-width: calc(33% - 46px);
        }
      }

      &._half {
        margin-right: 16px;
        max-width: calc(50% - 16px);

        @include mq(lg) {
          margin-right: 46px;
          max-width: calc(50% - 46px);
        }
      }

      &._full {
        max-width: 100%;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 20%;
  }
}
