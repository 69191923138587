.nes-counter-badge {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48px;
  height: 20px;
  background: #eaeaea;
  border-radius: 40px;
  cursor: pointer;

  &._no-margin {
    margin: 0;
  }

  .badge-counter {
    width: 20px;
    height: 20px;
    background-color: $mda-color-200;
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .11), 0 1px 1px 0 rgba(0, 0, 0, .12);
  }

  .badge-icon {
    width: 24px;
    height: 20px;
    line-height: 24px;
    text-align: center;
    margin-left: 2px;

    mat-icon {
      width: 14px;
      height: 14px;
      line-height: 14px !important;
      color: #9f9f9f;
    }
  }
}
