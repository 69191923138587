@mixin vehicle-details-blockages-theme($theme){
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);

    $primary-700: mat-color($primary, 700);
    $accent-600: mat-color($accent, 600);
    vehicle-details-blockages{
        nes-portlet-title{
            ._title{
                border-right: 4px solid $accent-600 !important;
            }
        }
        mat-header-cell{
            color: $accent-600 !important;
        }
        mat-row.inactive mat-cell{
            color: $primary-700 !important;
        }
        .nes-portlet-footer{
            .mat-button.form-submit-button{
                background-color: $accent-600;
                box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2), 0 0 1px 0 darken($color: $accent-600, $amount: 10%);
            }
        }
    }
}