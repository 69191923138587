
@mixin vehicle-details-general-theme($theme){
    $primary: map-get($theme, primary);

    $nes-primary-600:  mat-color($primary, 600);
    
    .vehicle_details_general_form {
        ::ng-deep mat-form-field[input-status="DISABLED"]{
            .mat-form-field-underline{
                background-color: #E9E9E9 !important;
            }
        }
    }
    .select_option_button{
        background-color: $nes-primary-600;
        .mat-option-text{
            color: #fff;
            mat-icon{
                color: #fff;
                margin-right: 4px;
            }
        }
        &:hover:not(.mat-option-disabled),&.mat-active{
            background-color: darken($nes-primary-600,10%);   
        }
    }

}