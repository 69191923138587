.nes-portlet-form {
  padding: 40px 0 0;

  &.off-canvas {
    background: $mda-gray-100;
    padding: 40px;
    margin-left: -40px;
    width: 100%;
  }

  &-wr {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &._spaced {
      padding-top: 40px;
    }
  }

  &-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    &.outside {
      padding: 30px 0 55px;
    }
  }

  &-col {
    flex: 1 1 100%;
    flex-direction: column;

    &._two {
      flex-basis: 50%;
      margin-right: 40px;

      @include mq(lg) {
        margin-right: 200px;
      }
    }

    &._three {
      flex-basis: 33%;
      margin-right: 20px;

      @include mq(lg) {
        margin-right: 100px;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    ._radio_group {
      margin-top: 10px;
      margin-bottom: 17px;
    }

    ._input_group {
      padding-top: 14px;
    }

    ._button_group {
      padding-top: 18px;
    }
  }

  //&-button {
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //
  //  nes-input-phone {
  //    flex: 1 1 100%;
  //  }
  //
  //  .mat-icon-button {
  //    @include size(20px);
  //    line-height: 18px;
  //    background: $mda-gray-600;
  //    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(84, 112, 156, 1);
  //
  //    mat-icon {
  //      @include size(10px);
  //      line-height: 10px;
  //      color: #fff;
  //    }
  //  }
  //}

  .mat-form-field {
    display: inline;

    &-infix {
      width: 100% !important;
    }
  }
}
