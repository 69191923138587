.nes-list-contact {
  margin: 20px 0 0 0;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    font-size: 12px;
    padding: 12px 0;
    position: relative;

    .mat-icon-button {
      position: absolute;
      left: -34px;
      @include size(22px);
      line-height: 20px;
      background-color: $mda-gray-450;
      box-shadow: 0 1px 1px 0 rgba(158, 160, 163, .73);

      .mat-icon {
        @include size(16px);
        line-height: 16px;
        color: #fff;
      }
    }
  }

  &-title {
    border-bottom: 5px solid $mda-gray-200;
    margin-bottom: 10px;

    &-wr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    &-item {

      &._title {
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 8px 0;
        padding-left: 10px;
        border-left: 4px solid $mda-color-200;
      }
    }
  }
}
