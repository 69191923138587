.nes-tabs-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 4px 20px 4px;
  border-bottom: 5px solid $mda-gray-100;
  margin-bottom: 40px;

  &-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 150px;

    &:last-child {
      margin-right: 0;
    }

    .nes-toggle-option {
      font-size: 12px;
      color: $mda-gray-700;

      &.left {
        padding-right: 12px;
      }

      &.right {
        padding-left: 12px;
      }

      &.active {
        color: $mda-gray-800;
      }
    }
  }
}

.nes-tabs-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 4px 0 4px;
  margin-bottom: 40px;

  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }
}
