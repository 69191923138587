.nes-input-vat-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  mat-form-field {
    flex: 1 1 120px;
    max-width: 120px;

    &:first-of-type {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }
}
