$columns: (
  10: (40px, 40px),
  20: (50px, 60px),
  30: (100px, 120px),
  40: (140px, 160px),
  50: (200px, 240px)
);

.nes-table-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin: 4px 0 20px 0;
  padding: 0 4px;
  border-bottom: 5px solid $mda-gray-100;

  &._forced_padded {
    padding: 0 44px;
  }

  &-box {
    display: flex;
    align-items: center;
    margin-left: 54px;

    mat-form-field.mat-form-field-disabled .mat-form-field-label {
      color: rgba(0, 0, 0, .87) !important;
    }

    &:first-child,
    &:nth-child(2) {
      margin-left: 0;
    }

    &:last-child {
      justify-content: flex-end;
      flex-grow: 1;
    }

    .mat-button {
      font-size: 12px;
      font-weight: 400;
      padding-left: 0;
      padding-right: 0;

      &:hover {
        color: $mda-color-200;

        .mat-button-focus-overlay {
          opacity: 0 !important;
        }
      }
    }
  }

  mat-checkbox {
    margin-right: 30px;

    .mat-checkbox-label {
      margin-left: 10px;
      font-size: 12px !important;
      font-weight: 400 !important;
      color: $mda-gray-900 !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  mat-icon {

    &.filter {
      @include size(22px);
      margin: 0 24px 0 0;
      color: $mda-color-200;
    }
  }
}

.nes-table-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 4px;
  background: $mda-gray-100;

  &._forced_padded {
    padding: 0 44px;
  }

  &-box {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      font-size: 12px !important;
      font-weight: 400 !important;
      color: $mda-gray-900 !important;
      margin-right: 16px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      .icon-box {
        box-shadow: none;
        text-align: center;
        margin-left: 8px;

        mat-icon {
          @include size(22px);
          color: $mda-color-200;
        }
      }

      .action-box {
        @include size(24px);
        background: $mda-color-200;
        box-shadow: 0 1px 3px 0 $mda-color-200;
        border-radius: 50%;
        line-height: 26px;
        text-align: center;

        mat-icon {
          @include size(12px);
          color: #fff;
        }
      }

      .icon-text {
        padding-left: 10px;
      }
    }
  }

  mat-checkbox {

    .mat-checkbox-label {
      margin-left: 18px;
      font-size: 12px !important;
      font-weight: 400 !important;
      color: $mda-gray-900 !important;
    }
  }

  mat-icon {

    &.separator {
      @include size(16px);
      margin: 0 20px;
      color: $mda-gray-900;
    }
  }
}

.nes-table {

  @each $size, $column in $columns {
    .col-#{$size} {
      flex: 0 0 nth($column, 1);

      @include mq(lg) {
        flex: 0 0 nth($column, 2);
      }
    }
  }

  .col {

    &-center {
      justify-content: center !important;
    }

    &-right {
      justify-content: flex-end !important;
    }

    &-action {
      display: flex;
      justify-content: center;
    }
  }

  .unread {
    font-weight: 600;
  }
}
