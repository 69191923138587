$columns: (
  5: (5%, 20px),
  10: (10%, 55px),
  20: (20%, 65px),
  30: (30%, 90px),
  40: (40%, 165px),
  50: (50%, 175px),
  60: (60%, 475px),
  70: (70%, 270px),
  80: (80%, 340px),
  90: (90%, 340px),
  100: (100%, 100%),
);

.nes-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding: 0 4px 20px 4px;
  border-bottom: 5px solid $mda-gray-100;
  margin-bottom: 40px;

  &-title {
    display: block;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: right;
    margin: 10px 4px;
    padding-right: 10px;
    border-right: 4px solid $mda-color-200;
  }

  &-column {
    flex-grow: 1;
    max-width: 375px;

    @include mq(lg) {
      max-width: 545px;
    }

    @include mq(xl) {
      max-width: 645px;
    }
  }

  &-row {

    mat-form-field {
      margin-bottom: 10px;
    }
  }

  &-row-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.justify-start {
      justify-content: flex-start;

      .nes-form-field {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.justify-end {
      justify-content: flex-end;

      .nes-form-field {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    mat-form-field {
      margin: 0 15px 10px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-field {
    text-align: right;

    &.left {
      text-align: left;
    }

    @each $size, $column in $columns {
      &.col-#{$size} {
        width: nth($column, 1);
        //max-width: nth($column, 2);

        @include mq(lg) {
          //max-width: nth($column, 3);
        }
      }
    }

    .mat-icon-button {
      @include size(21px);
      background: $mda-gray-600;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(84, 112, 156, 1);

      mat-icon {
        @include size(21px);
        position: absolute;
        top: -1px;
        left: 0;
        font-size: 18px;
        color: #fff;
      }
    }
  }

  mat-form-field {
    display: inline-block;

    @each $size, $column in $columns {
      &.col-#{$size} {
        width: nth($column, 1);
      }
    }

    .mat-form-field-label {
      color: $mda-gray-300 !important;

      .mat-placeholder-required {
        position: absolute;
        margin-left: 10px;
        font-size: 20px;
        color: $mda-color-200;
      }
    }
  }
}
