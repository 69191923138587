

@mixin global-dialogs-theme($theme) {
    
    .cdk-overlay-container{
        mat-dialog-container{
        padding: 25px 60px 40px 50px !important;
        width: 96vw;
        max-width: 1000px;
        position: relative;
        }
        .dossier-type-selection-model-dialog{
        mat-dialog-container{
            max-width: 600px;
        }
        }
        .new-vehicle-brand-dialog{
        mat-dialog-container{
            max-width: 600px;
        }
        }
    }
}